import React from 'react';
import Card from './Card';
import Section from './Section';

export default function Details() {

  return (
    <>
    <Card />
    <Section />
    </>
  )
}
